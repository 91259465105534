import '@stigg/react-sdk/dist/styles.css';
import {StiggProvider, SubscribeIntentionType, Paywall} from "@stigg/react-sdk";

const STIGG_CLIENT_API_KEY = import.meta.env.PUBLIC_STIGG_CLIENT_API_KEY || '';

export default function PricingStigg() {
    return (
<div className="py-2">
    <StiggProvider apiKey={STIGG_CLIENT_API_KEY}>
        <Paywall
            highlightedPlanId={'plan-lumen-pro'}
            productId="product-lumen"
            onPlanSelected={({plan, intentionType}) => {
                console.log('I want to subscribe', {plan, intentionType})
                switch (intentionType) {
                    case SubscribeIntentionType.START_TRIAL:
                        // TODO: provision trial subscription

                        break;
                    case SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS:
                        // TODO: redirect to contact us form

                        break;
                    case SubscribeIntentionType.UPGRADE_PLAN:
                    case SubscribeIntentionType.DOWNGRADE_PLAN:
                        // TODO: show checkout experience

                        break;
                }

                window.location.href = `/forms/contact?plan=${plan.id}`
            }}/>
    </StiggProvider>
</div>
    )
}